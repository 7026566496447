// import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/css';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import axios from 'axios'
import { createApp } from 'vue';
// import { createApp } from VueElement
// import * as THREE from 'three';
// gsap scrollTrigerr
gsap.registerPlugin(ScrollTrigger);
/**
 * 現在一から上スクロールでクラスふよ
 */

class ScrollDownToggleClass {
  constructor(target, border = 56) {
    this.target = target;
    this.border = border;
    this.offset = 0;
    this.lastPosition = 0;
    this.ticking = false;
  }
  toggleClass() {
    this.lastPosition = window.scrollY;
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        if (this.lastPosition > this.border) {
          if (this.lastPosition > this.offset) {
            this.target.classList.add('is-hide');
          } else {
            this.target.classList.remove('is-hide');
          }
          this.offset = this.lastPosition;
        }
        this.ticking = false;
      });
      this.ticking = true;
    }
  }
}
/**
 * 
 * @param {*} min 
 * @param {*} max 
 * @returns ランダム数値
 */
const getRandom = (min, max) => {
  const delta = max - min;
  return (direction = 1) => (min + delta * Math.random()) * direction;
}

function rotate(target, direction) {

  gsap.to(target, randomTime2(), {
    rotation: randomAngle(direction),
    // delay: randomDelay(),
    // ease: Sine.easeInOut,
    onComplete: rotate,
    onCompleteParams: [target, direction * -1]
  });
}

function moveX(target, direction) {

  gsap.to(target, randomTime(), {
    x: randomX(direction),
    // ease: Sine.easeInOut,
    onComplete: moveX,
    onCompleteParams: [target, direction * -1]
  });
}

function moveY(target, direction) {
  gsap.to(target, randomTime(), {
    y: randomY(direction),
    // ease: Sine.easeInOut,
    onComplete: moveY,
    onCompleteParams: [target, direction * -1]
  });
}




const randomRectFadeIn1 = (c, tOrign = "right") => {
  c.forEach((element) => {
    gsap.set(element, {
      scaleX: 0,
      transformOrigin: tOrign,
    });
    gsap.to(element, {
      duration: getRandom(.5, 2.5)(),
      delay: getRandom(.5, 2.5)(),
      ease: "power2.out",
      scaleX: 1,
    });
  });
}
const randomRectFadeIn2 = (c, tOrign = "right", target = "") => {
  c.forEach((element) => {
    gsap.set(element, {
      scaleX: 0,
      transformOrigin: tOrign,
    });
    gsap.to(element, {
      duration: getRandom(.5, 2.5)(),
      delay: getRandom(.5, 2.5)(),
      ease: "power2.out",
      scaleX: 1,
      scrollTrigger: {
        trigger: target,
        start: 'top bottom',
      }
    });
  });
}

/**
 * @desc スクロールであるポイントを超えたらクラスを付与
 * @param {*} targetEl  
 * @param {*} togglePointEl 
 * @param {*} toggleClass 
 */
const scrollInToggleClass = (targetEl, togglePointEl, toggleClass) => {
  if (togglePointEl) {
    const observer1 = new IntersectionObserver((entries) => {
      for (const e of entries) {
        if (e.isIntersecting) {
          targetEl.classList.remove(toggleClass);
        } else {
          targetEl.classList.add(toggleClass);
        }
      }
    });
    observer1.observe(togglePointEl);
  } else {
    if (targetEl) {
      targetEl.classList.add(toggleClass);
    }
  }
};
/**
 * @desc 一文字づつ文字をラップする
 * @param {*} target 
 * @returns 一文字つづspanでラップしたテキスト
 */
const spanWrapText = (target) => {
  const nodes = [...target.childNodes]; // ノードリストを配列にする
  let returnText = ''; // 最終的に返すテキスト

  for (const node of nodes) {
    if (node.nodeType == 3) {
      //テキストの場合
      const text = node.textContent.replace(/\r?\n/g, ''); //テキストから改行コード削除
      const splitText = text.split(''); // 一文字ずつ分割
      for (const char of splitText) {
        returnText += `<span>${char}</span>`; // spanタグで挟んで連結
      }
    } else {
      returnText += node.outerHTML;
    }
  }
  return returnText;
};



/*
const sectionTitles = gsap.utils.toArray('.js-animation-title1');
if (sectionTitles) {
  sectionTitles.forEach((box, index) => {
    const t1 = box.querySelector(".c-section-title")
    const t2 = box.querySelector(".c-section-title__ruby")
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: box,
        start: 'top 90%',
      }
    });
    tl.from(t2, {
      opacity: 0,
      y: 15,
      delay: 0.5,
      duration: 0.5,
    });
    tl.from(t1, {
      opacity: 0,
      y: 15,
      delay: 0.5,
      duration: 1,
    })    
  });
}
*/
document.querySelectorAll(".js-animation-title1").forEach((e) => {
  const mainText = e.querySelector(".c-section-title__ruby");
  const subText = e.querySelector(".c-section-title");
  mainText.innerHTML = spanWrapText(mainText)
  const spans = mainText.querySelectorAll('span');
  gsap.set(spans, { opacity: 0, })
  gsap.set(subText, { opacity: 0, y: 15, })

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: e,
      start: 'top 90%',
    }
  });
  tl.to(spans, {
    opacity: 1,
    duration: 0.02,
    stagger: 0.08,
  });
  tl.to(subText, {
    opacity: 1,
    y: 0,
    delay: 0.25,
    duration: 0.5,
  })
})


const fadeInEls = gsap.utils.toArray('.js-fadein-section');
if (fadeInEls) {
  fadeInEls.forEach((box, index) => {
    const f1 = box.querySelector(".section-figure")
    const l1 = box.querySelector(".c-label")
    const t1 = box.querySelector(".c-section-title-sub")
    const t2 = box.querySelector(".c-section-text")
    const t3 = box.querySelector(".c-data-table")
    const b1 = box.querySelectorAll(".c-button")
    gsap.set(f1, { opacity: 0, y: -30, scale: 1.5, })
    if (l1) {
      gsap.set(l1, { opacity: 0, y: 30,})
    }
    gsap.set(t1, { opacity: 0, y: 30,})
    gsap.set(t2, { opacity: 0, y: 30,})
    if (t3) {
      gsap.set(t3, { opacity: 0, y: 30,})
    }
    gsap.set(b1, { opacity: 0, y: 30,})

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: box,
        start: 'top 70%',
      }
    });
    tl.to(f1, { opacity: 1, y: 0, scale: 1, delay: 0.25, duration: 0.3, })
    if (l1) {
      tl.to(l1, { opacity: 1, y: 0, delay: 0, duration: 0.25, })
    }
    if (t1) {
      tl.to(t1, { opacity: 1, y: 0, delay: 0, duration: 0.25, })
    }
    tl.to(t2, { opacity: 1, y: 0, delay: 0, duration: 0.25, })
    if (t3) {
      tl.to(t3, { opacity: 1, y: 0, delay: 0, duration: 0.25, })
    }
    tl.to(b1, { opacity: 1, y: 0, delay: 0, duration: 0.75, })
  });
}

const stagger1 = gsap.utils.toArray('.js-stagger-anime1');
if (stagger1) {
  stagger1.forEach((box, index) => {
    // const items = box.querySelectorAll('.c-article-card')
    gsap.set(box, { opacity: 0, y: 30, scale: 1.25 })
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: box,
        start: 'top 90%',
      }
    });
    tl.to(box, {
      opacity: 1,
      y: 0,
      scale: 1,
      stagger: 0.5,
    });
  });
}


const fade1 = gsap.utils.toArray('.js-fade-anime1');
if (fade1) {
  fade1.forEach((box, index) => {
    gsap.set(box, { opacity: 0, y: 120 })
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: box,
        start: 'top 90%',
      }
    });
    tl.to(box, {
      opacity: 1,
      y: 0,
    });
  });
}

const heroT1 = document.querySelector('.js-hero__read');
const heroT2 = document.querySelector('.js-hero__catch');
// const loading = document.querySelector('.loading');
// const loadingTitle = document.querySelector('.loading__title');
if (heroT1 && heroT2) {
  // gsap.set(loadingTitle, { opacity: 0, y: -30, scale: 1.25 });
  gsap.set(heroT1, { opacity: 0, y: 50 })
  gsap.set(heroT2, { opacity: 0, y: 50 })
  const tl = gsap.timeline();
  // tl.to(loadingTitle, {
  //   opacity: 1,
  //   y: 0,
  //   scale: 1,
  //   duration: 1.5,
  // });
  // tl.to(loading, {
  //   xPercent: 100
  // })
  tl.to(heroT1, { opacity: 1, y: 0, duration: .75, delay: 0 })
  tl.to(heroT2, { opacity: 1, y: 0, duration: .75, delay: 0 })
}




/****/
//

/***
 * swiper
 */
window.addEventListener("DOMContentLoaded", () => {
  /***
   * ロゴのループスライダー
   */
  const swiperLogos = document.querySelector('.swiper--logos');
  if (swiperLogos) {
    const swiperSlides = swiperLogos.querySelector('.swiper-slide');
    const breakPoint = 768;
    let mySwiper;
    let mySwiperBool;
    window.addEventListener('load', () => {
      if (breakPoint < window.innerWidth) {
        mySwiperBool = false;
      } else {
        createSwiper();
        mySwiperBool = true;
      }
    }, false);
    window.addEventListener('resize', () => {
      if (breakPoint < window.innerWidth && mySwiperBool) {
        mySwiper.destroy(false, true);
        mySwiperBool = false;
      } else if (breakPoint >= window.innerWidth && !(mySwiperBool)) {
        createSwiper();
        mySwiperBool = true;
      }
    }, false);
    const createSwiper = () => {
      mySwiper = new Swiper(swiperLogos, {
        speed: 4000,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
        spaceBetween: 40,
        slidesPerView: 'auto',
        loop: true,
        loopedSlides: swiperSlides.length,
        centeredSlides: true
      });
    }
  }
});


/*
 */
class News {
  constructor(newsResponseData) {
    this.buildData(newsResponseData);
  }
  formatDate(date) {
    const d = new Date(date);
    /** 日付を文字列にフォーマットする */
    return `${d.getFullYear()}.${(d.getMonth() + 1).toString().padStart(2, '0')}.${d.getDate().toString().padStart(2, '0')}`
      .replace(/\n|\r/g, '');
  }
  buildData(newsResponseData) {
    this.date = this.formatDate(newsResponseData.date);
    this.title = newsResponseData.title.rendered
    this.category_name = newsResponseData.category_name
    this.thumbnail_url = newsResponseData.thumbnail_url
    this.link = newsResponseData.link
  }
}
class Api {
  baseUrl = 'https://taliki.org/wp-json/wp/v2/';
  constructor(response) {
  }
  buildNewsList(response) {
    const newsList = [];
    for (const news of response.data) {
      newsList.push(new News(news));
    }
    return newsList;
  }
  getNewsList(arg) {
    const url = this.baseUrl + "posts?" + arg;
    return axios.get(url).then((response) => {
      return this.buildNewsList(response)
    })
  }
}





const articles = new Api();
const $articles = $('.js-archive');
if ($articles.length != 0) {
  articles.getNewsList("per_page=5&categories=183&tags=167").then((newsList) => {
    for (const news of newsList) {
      const $card = $("<a></a>", {
        href: news.link,
        target: "_blank",
        "class": "c-article-card js-stagger-anime-archive"
      })
      const $cardImage = $('<div />', { "class": "c-article-card__image" })
        .append('<img src="' + news.thumbnail_url + '" />');

      const $cardBody = $('<div />', { "class": "c-article-card__body" })
        .append('<div class="c-article-card__info">' + news.date + '</div>')
        .append('<h3 class="c-article-card__title">' + news.title + '</h3>');

      $card.append($cardImage).append($cardBody)
      $articles.append($card)
    }
    const stagger3 = gsap.utils.toArray('.js-stagger-anime-archive');
    if (stagger3) {
      stagger3.forEach((box, index) => {
        gsap.set(stagger3, { opacity: 0, y: 30, scale: .6 })
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: box,
            start: 'top 90%',
          }
        });
        tl.to(stagger3, {
          opacity: 1,
          y: 0,
          scale: 1,
          stagger: 0.3,
        });
      });
    }
  })
}


const $articleSlider = $('.js-articles-slider');
if ($articleSlider.length != 0) {
  articles.getNewsList("per_page=5&tags=167").then((newsList) => {
    for (const news of newsList) {
      const template =
        `<div class="swiper-slide c-slide-item">
        <div class="c-slide-item__visual mb-medium mb-small_sp"><img src="${news.thumbnail_url}" alt="${news.title}"></div>
        <h4 class="c-slide-item__title mb-medium mb-small_sp">${news.title}</h4>
        <a class="c-button" target="_blank" href="${news.link}"><span>READ MORE </span></a>
      </div>`;
      $articleSlider.find('.c-slide-items').append(template);
    }
    const swiperAuto = document.querySelector('.swiper--auto');
    if (swiperAuto) {
      const swiperAutoSlides = swiperAuto.querySelector('.swiper-slide');
      const slider4 = new Swiper(swiperAuto, {
        slidesPerView: "auto",
        spaceBetween: 32,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        breakpoints: {
          // 768px以上の場合
          768: {
            spaceBetween: 48,
          },
        },
        loop: true,
      });
    }
  })
}


